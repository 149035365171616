import React from 'react'
import { useState, useRef } from 'react'
import './Contact.css'
import phone from '../../images/phone.svg'
import email from '../../images/email.svg'
import pattern from '../../images/ar-pattern.png'
import thankyou from '../../images/thankyou.svg'

const Contact = () => {
    const [formData, setFormData] = useState({
        email: '',
        desk: '',
    });


    const [errors, setErrors] = useState({});
    const form = useRef();

    const [showForm, setShowForm] = useState(true)
    const [showThankyou, setShowThankyou] = useState(false)

    function showError(inputName, message) {
        setErrors((prevErrors) => ({
            ...prevErrors,
            [inputName]: message,
        }));
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
        showError(name, '');
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const validationErrors = validateForm(formData);
        console.log(Object.keys(validationErrors).length)
        if (Object.keys(validationErrors).length === 0) {
            setShowThankyou(true)
            setShowForm(false)
            console.log('Form is valid ')
            const config = {
                SecureToken: '8ab58048-1fb6-4180-84ba-84975d755122',
                To: 'info@idrakai.com',
                From: "info@idrakai.com",
                Subject: 'Contact Form',
                Body: `Email:  ${formData.email}, 
                        MESSAGE:  ${formData.desk}`
            }
            window.Email.send(config).then((e, c, p, d) => console.log(e, c, p, d))
        } else {
            setErrors(validationErrors);
        }
    };

    const validateForm = (data) => {
        const errors = {};

        if (!data.email) {
            errors.email = 'Email is required*';
        } else if (!/\S+@\S+\.\S+/.test(data.email)) {
            errors.email = 'Invalid email format*';
        }
        if (!data.checkbox) {
            errors.checkbox = 'You must agree to terms*';
        }
        return errors;
    };
    return (
        <>
            <div className="contact-wrapper" id='Contact'>
                <div className="gradient-contact"></div>
                <div className="inner-left">
                    <div className="inner-heading">
                        <h1>Let's Talk...</h1>
                        <p>Take the Next Step Towards Innovation</p>
                    </div>
                    <div className="contact-info">
                        <div className="phone-no">
                            <div className='phone'>
                                <img src={phone} alt="" />
                                <span className='Phone-num'>Phone num</span>
                            </div>
                            <div className='number'><a href="tel:+44 (7902) 751142" style={{ textDecoration: 'none', color: 'white' }}>+44 (7902) 751142</a>
                            </div>
                        </div>
                        <div className="email-id">
                            <div className='email'>
                                <img src={email} alt="" />
                                <span className='Email-ID'>Email</span>
                            </div>
                            <div className='-email'><a href="mailto:info@idrakai.com" style={{ textDecoration: 'none', color: 'white' }}>info@idrakai.com</a></div>
                        </div>
                    </div>
                    <div className='address'>
                        <a href="https://maps.app.goo.gl/L15LqoDTCoSdoFGm7" style={{ textDecoration: 'none', color: 'white' }}>
                            1.6 Southgate Business Park, Normanton Road, Derby, England, DE23 6UQ
                        </a>
                    </div>
                </div>
                <div className="inner-right">
                    <img src={pattern} className='pattern-contact' alt="" />
                    {showForm && (
                        <form ref={form} onSubmit={handleSubmit}>
                            <div className='form-email'>
                                <label htmlFor="Email">Email</label>
                                {errors.email && <span className="error">{errors.email}</span>}
                                <input
                                    type="text"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className='form-message'>
                                <label htmlFor="Message">Message</label>
                                {errors.desk && <span className="error">{errors.desk}</span>}
                                <input
                                    type="text"
                                    name="desk"
                                    value={formData.desk}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div>
                                {errors.checkbox && <span className="error">{errors.checkbox}</span>}
                                <div className='form-checkbox'>
                                    <input
                                        type="checkbox"
                                        name="checkbox"
                                        checked={formData.checkbox}
                                        onChange={handleChange}
                                        required
                                        style={{ marginRight: '10px' }}
                                    />
                                    <label htmlFor="Checkbox">
                                        By clicking 'SUBMIT,' you agree to receive promotional phone calls and SMS messages from IDRAK Ai for marketing purposes. Message frequency depends on your activity. Message and data rates may apply. Text HELP for help & STOP to cancel. <br />
                                        <br />
                                        You also agree to IDRAK Ai’s <a href="/Terms" target="_blank">Terms of Use</a> and  <a href="/Privacy" target="_blank">Privacy Policy.</a>
                                    </label>
                                </div>
                            </div>
                            <button onClick={handleSubmit}>Send</button>
                        </form>
                    )}
                    {showThankyou && (
                        <div className='thankyouwrapper'>
                            <div className='thankyoumessage'>
                                <img src={thankyou} alt="" />
                                <h1>Thank you!</h1>
                                <p>Your submission has been recieved</p>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className='bottom-bar'>
                <a href='/Privacy' target="_blank">Privacy Policy</a>
                <a href='/Terms' target="_blank">Terms of Use</a>
            </div>
        </>
    )
}

export default Contact