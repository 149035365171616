import React, {useRef, useEffect} from 'react'
import './API.css'
import bg from '../../images/bg.png'
import facerecognition from '../../images/Videos/API.m4v'
import vectorapi from '../../images/vector-disc.svg'
import overlay from '../../images/overlay.png'
const API = () => {
    const vidRef = useRef(null);

    useEffect(() => {
        const video = vidRef.current;

        // Autoplay the video in a loop
        video.play();
        video.addEventListener('ended', () => {
            video.currentTime = 0;
            video.play();
        });
    }, []);
    return (
        <>
            <div className="API-wrapper" id='QuickDetect'>
                <video ref={vidRef} src={facerecognition}
                    muted
                    loop 
                    playsInline/>
                <div class="vignette-API">
                    <img src={overlay} alt="" />
                </div>

                <div className="gradient-II"></div>
                <div className="API-heading">
                    <h1>Quick Detect</h1>
                    <img src={vectorapi} />
                </div>
                <div className="API-embodies">
                    <div className="API-subsection">
                        <h2>01</h2>
                        <h1>Spot the One in 80,000!</h1>
                        <p>Capture faces in a heartbeat.</p>
                    </div>
                    <div className="API-subsection">
                        <h2>02</h2>
                        <h1>Unerring Accuracy</h1>
                        <p>Maintain High Security Standards</p>
                    </div>
                    <div className="API-subsection">
                        <h2>03</h2>
                        <h1>Zero Guesswork</h1>
                        <p>No more false positives.</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default API
