import React from 'react'
import './Reg.css'
import HeroSec from './HeroSec'
import LearnSec from './LearnSec'
import BulletSection from './BulletSection'
import Form from './Form'
const Registration = () => {
    return (
        <>
            <div className='registration-main'>
                <HeroSec />

                <LearnSec />

                <BulletSection />

                <Form />
            </div>
        </>
    )
}

export default Registration
