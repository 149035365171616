import React, { useEffect, useState, useRef } from 'react'
import '../../Pages/Home/Home.css'
import closebutton from '../../images/closebutton.svg'
import rcci from '../../images/rcci.png'
import awards from '../../images/awards.png'
import trophy from '../../images/trophy.png'
import logocallwise from '../../images/callwise.png'
import headerpopup from '../../images/header-popup.png'
import redirect from '../../images/redirect.png'
import scrollarrow from '../../images/Vector.svg'
import effect from '../../images/Videos/effect.m4v'
import SectionII from '../../Components/Section-II/SectionII'
import dots from '../../images/3 dots.png'
import line from '../../images/line.png'
import line2 from '../../images/Line 2.png'
import dotted from '../../images/doted.png'
import dotted2 from '../../images/dotted2.png'
import Discovery from '../../Components/Discovery/Discovery'
import Callwise from '../../Components/Callwise/Callwise'
import Medical from '../../Components/Medical/Medical'
import API from '../../Components/API/API'
import Agri from '../../Components/Agriculture/Agri'
import AR from '../../Components/AR/AR'
import Contact from '../../Components/Contact/Contact'
// import Vision from '../../Components/Vision/Vision'
import Testimonials from '../../Components/Testimonials/Testimonials'
import { Modal } from 'react-bootstrap'
import Loader from '../../Components/Loader/Loader'
import logoowl from '../../images/logoowl.svg'

const Home = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [showModal, setshowModal] = useState(false)
  const closeModal = () => setshowModal(false);
  const vidRef = useRef(null);

  useEffect(() => {
    const video = vidRef.current;

    video.play();
    video.addEventListener('ended', () => {
      video.currentTime = 0;
      video.play();
    });
  }, []);
  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 50) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  return (
    <>
      <Loader loadingTimeInSeconds={15} imageUrl={logoowl} />
      <Modal
        show={showModal}
      >
        <div className='closebutton' onClick={closeModal}>
          <img src={closebutton} alt="close" />
        </div>
        <div className='context'>
          <div className='header-image'>
            <img src={headerpopup} alt='headerpopimage' />
          </div>
          <div className="text">
            <h2>Elevating Pakistan to Excellence; <span className='blacktext'>IDRAK</span> recognized
              as the <span className='blacktext'>Nation's Best Artificial Intelligence Company.</span></h2>
          </div>
          <div className="bottom-logos">
            <div className='bottom-left'>
              <div className='trophy'>
                <img src={trophy} alt='trophyimage' />
              </div>
              <div className='text-logo-left'>
                <div>
                  <p>Awarded Product</p>
                </div>
                <div className='callwiselogo'>
                  <img src={logocallwise} alt="callwiselogo" />
                </div>
              </div>
            </div>
            <div className='bottom-right'>
              <div className='bottom-right-left'>
                <div>
                  <img src={rcci} alt='' />
                </div>
                <div>
                  <a href='https://www.idrakai.com/'>www.idrakai.com |&nbsp;</a>
                </div>
              </div>
              <div className="bottom-right-right">
                <div>
                  <img src={awards} alt='award-logo' />
                </div>
                <div>
                  <a href='https://callwise.ai/'>www.callwise.ai</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='popup-button'>
          <button><a href='https://callwise.ai/'>View Callwise </a><img src={redirect} alt='redirect-button' /></button>
        </div>
      </Modal>
      <div className='scrollup'>
        <img src={scrollarrow} alt='arrowhead' onClick={scrollToTop} />
      </div>
      <div className="vectors">
        <div className='vectorI'></div>
        <div className='vectorII'></div>
        <div className='vectorIII'></div>
      </div>
      <div className='homebanner'>
        <div className='homepage-banner'>
          <h1>We're Architects of <span className='heading-span'> Change</span><span style={{ fontSize: "61.128px" }}>,</span></h1>
          <h2> Building<span className='heading-span'> Future </span>with<span className='heading-span'> AI.</span></h2>
          <p className='heading-tagline'>Idrak embodies the belief that the integration of technology and humanity can achieve unprecedented greatness. </p>
          <p className='spacing-p'>
            We go beyond being AI developers, serving as agents of <span style={{ fontWeight: '700' }}>change</span>, creators of <span style={{ fontWeight: '700' }}>opportunities</span>, and <span style={{ fontWeight: '700' }}>catalysts</span> for <span style={{ fontWeight: '700' }}>growth</span>.</p>
          <div className="gradient-I"></div>
        </div>
        <video
          ref={vidRef}
          src={effect}
          muted
          controls={false}
          loop
          playsInline
        />
        <div className='homepage-sectionII'>
          <img src={dots} className='dots' alt='' />
          <img src={dots} className='dots2' alt='' />
          <img src={line} className='line' alt='' />
          <img src={line} className='lineb' alt='' />
          <img src={line2} className='line-2' alt='' />
          <img src={dotted} className='dotted' alt='' />
          <img src={dotted2} className='dotted2' alt='' />
          <SectionII />
          <div className="linear-gradient"></div>
          <div className="linear-gradient-II"></div>
        </div>
      </div>
      <Discovery />

      <Callwise />

      <Medical />

      <API />

      <Agri />

      <AR />

      {/* <Vision /> */}

      <Testimonials />

      {/* <Contact /> */}

    </>
  )
}

export default Home
