import React, { useState, useEffect } from 'react';
import logoowlwhite from '../../images/logoowlwhite.svg'
import './Loader.css';

const Loader = ({ imageUrl, loadingTimeInSeconds }) => {
  const [progress, setProgress] = useState(0);
  const [loadingComplete, setLoadingComplete] = useState(false);
  const hasVisitedBefore = localStorage.getItem('visitedBefore');

  useEffect(() => {
    if (!hasVisitedBefore) {
      let startTimestamp;
      let animationFrameId;

      const animate = (timestamp) => {
        if (!startTimestamp) startTimestamp = timestamp;
        const progressPercentage = Math.min(
          (timestamp - startTimestamp) / (loadingTimeInSeconds * 1000) * 100,
          100
        );
        setProgress(progressPercentage);

        if (progressPercentage < 100) {
          animationFrameId = requestAnimationFrame(animate);
        } else {
          setLoadingComplete(true);
          localStorage.setItem('visitedBefore', 'true'); 
        }
      };

      animationFrameId = requestAnimationFrame(animate);

      return () => {
        cancelAnimationFrame(animationFrameId);
      };
    } else {
      setLoadingComplete(true);
    }
  }, [hasVisitedBefore, loadingTimeInSeconds]);

  return (
    <div className="backdrop" style={{ display: loadingComplete ? 'none' : 'flex' }}>
      <div className="loader-container">
        <div className="loader-bar">
          <img src={imageUrl} alt="Loading..." style={{ height: '200px' }} className="white" />
          <img src={logoowlwhite} alt="Loading..." style={{ height: '200px' }} className="transparent" />
        </div>
      </div>
    </div>
  );
};

export default Loader;
