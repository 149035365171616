import React, {useEffect, useRef} from 'react'
import './Discovery.css'
import earth from '../../images/Videos/earth-unedited.mp4'
import disbg from '../../images/Dis-bg.svg'
import embodiesbg from '../../images/embodies-bg.svg'
import vectordis from '../../images/vector-disc.svg'
import ellipse1 from '../../images/Ellipse1.svg'
const Discovery = () => {
  const vidRef = useRef(null);

  useEffect(() => {
    const video = vidRef.current;

    // Autoplay the video in a loop
    video.play();
    video.addEventListener('ended', () => {
      video.currentTime = 0;
      video.play();
    });
  }, []);
  return (
    <>
      <div className="discoverymain">
        <img src={disbg} className='bg-image'  alt=""/>
        <div className='discovery-wrapper'>
          <img src={embodiesbg} className='embodiesbg'  alt=""/>
          <img src={ellipse1} className='ellipse1'  alt=""/>
          <div className='discovery-inner-right'>
            <div className='main-heading'>
              <h1>Our Discoveries</h1>
              <h2>That drives impact.</h2>
            </div>
            <div className="vector-dis"><img src={vectordis} alt="" /></div>
            <div className="discovery-embodies">
              <div class="vignette-discovery"></div>
              <video ref={vidRef} src={earth}
                muted
                loop 
                playsInline/>
              <div className='embodies-inner'>
                <div className='embodies-subsection'>
                  <h3>01</h3>
                  <div>
                    <h1 style={{ width: 'fit-content' }}>REINVENTING CUSTOMER <br />INTERACTIONS</h1>
                    <p>Seamless, Tailored, Insightful</p>
                  </div>
                </div>
                <div className='embodies-subsection'>
                  <h3>02</h3>
                  <div>
                    <h1>HEALING WITH PRECISION</h1>
                    <p>Rapid, Pinpoint, Transformative</p>
                  </div>
                </div>
                <div className='embodies-subsectionA'>
                  <h3>03</h3>
                  <div>
                    <h1>REDEFINES FACE RECOGNITION</h1>
                    <p>Instant, Unerring, Game-changing</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Discovery
