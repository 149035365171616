import React from 'react'

const HeroSec = () => {
    return (
        <>
            <div className='heroSec-main'>
                <div className='hero-sec-gradient'/>
                <div className='heroSec-main-left'>
                    <h1>
                        <span>Unlock </span>the Power of <span>Vision </span>
                    </h1>
                    <p>
                        A Comprehensive <b>Computer Vision Workshop</b> Dive into the <b>World</b> of Computer Vision!
                    </p>
                </div>
                <div className='heroSec-main-right'>
                    <div className='main-right-date'>
                        <h2>
                            Registration From:
                        </h2>
                        <div className='main-right-date-para'>
                            <p>
                                12 April - 30 April <br />
                                Price : 1250 +VAT
                            </p>
                            
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HeroSec
