import React, { useRef, useState } from 'react'
import locpin from '../../images/locpin.png'
import clock from '../../images/clock.png'
import './Reg.css'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
const Form = () => {

    const [formState, setFormState] = useState({
        firstName: '',
        lastName: '',
        workEmail: '',
        company: '',
        phoneNumber: '',
        message: '',
    });

    const [errors, setErrors] = useState({});
    const form = useRef()
    function showError(inputName, message) {
        setErrors((prevErrors) => ({
            ...prevErrors,
            [inputName]: message,
        }));
    }

    const validateForm = (data) => {
        const errors = {};

        // Validation for email
        if (!data.workEmail) {
            errors.workEmail = 'Email is required*';
        } else if (!/\S+@\S+\.\S+/.test(data.workEmail)) {
            errors.workEmail = 'Invalid email format*';
        }

        // Validation for firstName
        if (!data.firstName) {
            errors.firstName = 'First name is required*';
        }

        // Validation for lastName
        if (!data.lastName) {
            errors.lastName = 'Last name is required*';
        }

        // Validation for company
        if (!data.company) {
            errors.company = 'Company name is required*';
        }

        // Validation for phoneNumber
        if (!data.phoneNumber) {
            errors.phoneNumber = 'Phone number is required*';
        } else if (!/^\+?[\d()\- ]+$/.test(data.phoneNumber)) { // Regex to include optional + sign, digits, parentheses, hyphens, and spaces
            errors.phoneNumber = 'Invalid phone number format*';
        }

        return errors;
    };
    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormState((prevData) => ({
            ...prevData,
            [name]: value
        }));
        showError(name, '');
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const validationErrors = validateForm(formState);
        console.log(Object.keys(validationErrors).length)
        if (Object.keys(validationErrors).length === 0) {
            console.log('Form is valid ')
            const config = {
                SecureToken: '8ab58048-1fb6-4180-84ba-84975d755122',
                To: 'info@idrakai.com',
                From: "info@idrakai.com",
                Subject: 'Registration Form',
                Body: `Name:  ${formState.firstName} ${formState.lastName}, 
                        Email:  ${formState.workEmail}, 
                        Phone Number: ${formState.phoneNumber}, 
                        Company: ${formState.company},
                        Message: ${formState.message}`
            }
            window.Email.send(config).then((e, c, p, d) => console.log(e, c, p, d))
            toast.success('Form Submitted Successfully')
        } else {
            toast.error('Error', validationErrors);
        }
    };

    return (
        <>
            <div className='contact-main'>
                <div className='form-heading-brochure'>
                    <h1>
                        Registration <span>Form</span>
                    </h1>
                </div>
                <form onSubmit={handleSubmit} className='contact-form' ref={form}>
                    <div className='input-divs'>
                        <input
                            type="text"
                            name="firstName"
                            value={formState.firstName}
                            onChange={handleChange}
                            placeholder='First Name'
                            required
                        />
                        <input
                            type="text"
                            name="lastName"
                            value={formState.lastName}
                            onChange={handleChange}
                            required
                            placeholder='Last Name'
                        />
                    </div>
                    <div className='flex justify-between input-divs'>
                        <input
                            type="tel"
                            name="phoneNumber"
                            value={formState.phoneNumber}
                            onChange={handleChange}
                            required
                            placeholder='Phone Number'
                        />
                        <input
                            type="email"
                            name="workEmail"
                            value={formState.workEmail}
                            onChange={handleChange}
                            required
                            placeholder='Email'
                        />
                    </div>
                    <input
                        type="text"
                        name="company"
                        value={formState.company}
                        onChange={handleChange}
                        required
                        placeholder='Company'
                    />
                    <textarea
                        name="message"
                        value={formState.message}
                        onChange={handleChange}
                        placeholder='Message'
                    />

                    <button type="submit" className='registration-button'>Submit</button>
                </form>
            </div>
            <div className='registration-bottom-bar'>
                <div className='registration-reserve'>
                    <h3>Reserve Your Spot Today!</h3>
                </div>
                <div className='registration-date'>
                    <img src={clock} alt="" />
                    <p>27 May - 31 May 2024</p>
                </div>
                <div className='registration-address'>
                    <img src={locpin} alt="" />
                    <p>Suite 1.6 Southgate Innovation
                        Centre, Normantan Road, Derby
                        DE23 6UQ</p>
                </div>
            </div>
        </>
    )
}

export default Form
