import React, {useEffect, useRef} from 'react'
import './Medical.css'
import xray from '../../images/Videos/new.m4v'
import bullet from '../../images/Bullet.svg'
import large from '../../images/large.png'
import mid from '../../images/mid.png'
import small from '../../images/small.png'
import square from '../../images/square.svg'
import medicaloverlay from '../../images/medicaloverlay.png'
const Medical = () => {
    const vidRef = useRef(null);

    useEffect(() => {
        const video = vidRef.current;

        // Autoplay the video in a loop
        video.play();
        video.addEventListener('ended', () => {
            video.currentTime = 0;
            video.play();
        });
    }, []);
    return (
        <>
            <div className="medical-wrapper" id='Medical'>

                <div className="medical-wrapper-inner-left">
                    <video ref={vidRef} src={xray}
                        muted
                        controls={false}
                        loop 
                        playsInline/>
                    <div class="vignette-medical"><img src={medicaloverlay} alt="" /></div>

                    <img src={square} className='square' alt=""/>
                    <div className='context-bullets'>
                        <div className="bullets">
                            <img src={bullet} alt="" />
                            <p>Drastically reduce waiting times.</p>
                        </div>
                        <div className="bullets">
                            <img src={bullet} alt=""/>
                            <p>Minimized errors, maximizing the accuracy.</p>
                        </div>
                        <div className="bullets">
                            <img src={bullet} alt=""/>
                            <p>Say goodbye to diagnostic ambiguity.</p>
                        </div>
                        <div className="bullets">
                            <img src={bullet} alt=""/>
                            <p>Streamlined processes ensures every step is optimized.</p>
                        </div>
                    </div>
                    <div className="gradient"></div>
                </div>
                <div className="medical-wrapper-inner-right">

                    <img src={large} className='bacteria-large' alt=""/>
                    <img src={mid} className='bacteria-mid' alt=""/>
                    <img src={small} className='bacteria-small' alt="" />

                    <h1>Medical Diagnostics</h1>
                    <p className='medical-paragraph'>Step into the future with IDRAK's medical marvels, where diagnostic hurdles are history, and precision rules the roost. Idrak transforms medical diagnostics, making health journeys swift and reliable. Say goodbye to traditional limitations and hello to precision. </p>
                    <div className='context-bullets-med'>
                        <div className="bullets">
                            <img src={bullet} alt=""/>
                            <p>Diagnosis at the speed of thought.</p>
                        </div>
                        <div className="bullets">
                            <img src={bullet} alt=""/>
                            <p>Precision that'll make you blink twice.</p>
                        </div>
                        <div className="bullets">
                            <img src={bullet} alt=""/>
                            <p>Streamlining the once complex.</p>
                        </div>
                        <div className="bullets">
                            <img src={bullet} alt=""/>
                            <p>Reliable paths to wellness.</p>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default Medical
