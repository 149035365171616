import React from 'react'
import './Privacy.css'
const Privacy = () => {
    return (
        <>

            <div className='privacy-banner'>
                <div className='privacy-heading'>
                    <h1> PRIVACY POLICY </h1>
                </div>

                <p>This Privacy Policy ("Policy") governs the collection, use, and disclosure of personal information on the IDRAK AI platform (the "Platform" or "Site" or "Web Site" or "Emails" or "Mobile Application" or "App" or "Us" or "We") owned and operated by IDRAK AI, LTD (hereinafter referred to as the "Company"), with registered office at 71-75 Shelton Street Covent Garden, London WC2H 9JQ, United Kingdom</p>

                <h3>A. INTRODUCTION</h3>
                <p>This Policy aims to protect the privacy of users of the Platform and outlines how the personal information provided by users will be used by the Company. By using the Platform, users explicitly agree to the terms of this Policy and the Terms & Conditions. Users should read this Policy carefully before using the services provided by the Platform.</p>
                <p>The Company reserves the right to update this Policy, and users are advised to check this page periodically for any changes.</p>

                <h3>B. USERS' CONSENT TO INFORMATION COLLECTION</h3>
                <p>Information provided by the user: By using or visiting the Platform, users expressly consent to the collection, processing, and use of their personal information as described in this Policy. The information provided by users is used to create personalized accounts, send updates, provide relevant services, conduct surveys, and improve the user experience.</p>
                <p>Device Information: The Platform may collect information about the devices used to access it, including device type, operating system, settings, unique device identifiers, and IP address.</p>

                <h3>C. HOW INFORMATION IS USED BY THE COMPANY</h3>
                <p>Personalization: Information provided by users is used to personalize content and provide relevant search results based on their preferences and previous interactions.</p>
                <p>Service Improvement: The information is used to improve the quality, functionality, and interactivity of the Platform, as well as for troubleshooting and data analysis.</p>
                <p>Internal Operations: The Company uses the information for internal purposes, such as conducting research, surveys, and generating reports.</p>
                <p>For informational SMS/text and voice recordings, the customer must provide their phone number and agree to be contacted in the future. In this case, explicit permission is required for the company to send informational text messages. Customers can grant this permission through opt-in methods such as texting a keyword, filling out a form, providing consent on a website, or giving verbal or written permission.</p>


                <h3>D. INFORMATION SHARING AND THIRD-PARTY DISCLOSURES</h3>
                <p>Legal Requirements: The Company may disclose user information when required by law, court order, or governmental agency for verification of identity or prevention, detection, and investigation of illegal activities.</p>
                <p>Merger or Sale: In case of reorganization or sale of assets, user information may be transferred to the acquirer, subject to their acceptance of this Policy.</p>
                <p>Marketing: The Company will not rent, sell, or share users' personal information for its marketing purposes, but may send service-related announcements and promotional communications.</p>

                <h3>E. CHOICE REGARDING PERSONAL INFORMATION</h3>
                <p>Users can opt out of promotional communications or deactivate their accounts if they no longer wish to receive such information.</p>

                <h3>F. TIPS FOR RESPONSIBLE USE OF THE PLATFORM</h3>
                <p>Users are advised to exercise caution while using the Platform, avoid sharing sensitive information publicly, and report any suspicious activities.</p>

                <h3>G. PRIVACY OF MINORS</h3>
                <p>Users must be 18 years of age or above to use the Platform. Minors can use the Platform under parental supervision.</p>

                <h3>H. UPDATES/AMENDMENTS TO THE POLICY</h3>
                <p>The Policy is subject to updates, and users will be notified of material changes. Continued use of the Platform after such updates implies acceptance of the amended Policy.</p>

                <h3>I. CONTACT US</h3>
                <p>For any queries related to this Policy, users can contact customer care at <a href="tel:+44 (7902) 751142">+44 (7902) 751142</a></p>

                <h3>J. DISCLAIMER</h3>
                <p>The Company reserves the right to amend the Policy, and continued use of the Platform after such changes constitutes acceptance of the updated Policy. Users are responsible for protecting their personal information, and the Company is not liable for any breaches beyond its control. The Company takes commercially acceptable security measures to safeguard personal data but cannot guarantee absolute security.</p>

                <h3>K. DATA SHARING AND THIRD-PARTY USE OF INFORMATION</h3>
                <p>No mobile information will be shared with third parties/affiliates for marketing/promotional purposes. Information sharing to subcontractors in support services, such as customer service is permitted. All other use case categories exclude text messaging originator opt-in data and consent; this information will not be shared with any third parties.</p>

            </div>
            <div className='bottom-bar'>
                <a href='/'>@idrak.ai</a>
                <a href='/Privacy'>Privacy Policy</a>
                <a href='/Terms'>Terms of Use</a>
            </div>
        </>
    )
}
export default Privacy
