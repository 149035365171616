import React, {useRef, useEffect} from 'react'
import './AR.css'
import arbg from '../../images/AR-bg.svg'
import arpattern from '../../images/AR-PATTERNN.png'
import ARvideo from '../../images/Videos/AR BOOK 2.mp4'
const AR = () => {
  const vidRef = useRef(null);

  useEffect(() => {
    const video = vidRef.current;
    video.play();
    video.addEventListener('ended', () => {
      video.currentTime = 0;
      video.play();
    });
  }, []);
  return (
    <>
      <div className='AR-wrapper' id='AR'>
        <img src={arbg} className="AR-bg-black" />
        <div className='ar-left'>
          <img src={arpattern} className='arpattern' />
          <div className="gradientAR"></div>
          <video ref={vidRef} src={ARvideo}
            muted
            controls={false}
            loop
            playsInline
          />
          <div className='vignetteAR'></div>
        </div>
        <div className="ar-right">
          <div className="ar-context">
            <h1>AR BOOKS</h1>
            <p>Venture into a vibrant universe where imagination knows no bounds with augmented reality, a revolution that fuses the magic of storytelling with the power of advanced AI technology. A masterpiece of Illustration, Storytelling, and 3D design, AR Books are more than just a products of augmented reality - it's an immersive experience.</p>
            <p>With the help of groundbreaking artificial intelligence solutions, we've brought a new dimension to reading. AR Book provides captivating animations and engaging voiceovers, enveloping readers in a world that leaps off the pages and into their reality.</p>
          </div>
        </div>
      </div>


    </>
  )
}

export default AR