import React from 'react'
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.bundle';
import Home from './Pages/Home/Home'

import { BrowserRouter as Router, Routes, Route, createBrowserRouter, RouterProvider } from "react-router-dom";
import Navbar from './Components/Navbar/Navbar'
import Terms from './Components/Terms/Terms';
import Privacy from './Components/Terms/Privacy'
import Root from './Components/root/root';
import Contact from './Components/Contact/Contact';
import Registration from './Components/Registration/Registration';
import { ToastContainer } from 'react-toastify';
const App = () => {
  const router = createBrowserRouter([
    {
      path: '/',
      element: <Root />,
      children:
        [{
          path: "/",
          element: (
            <Home />
          ),
        },
        {
          path: "/Terms",
          element: (
            <Terms />
          ),
        },
        {
          path: "/Privacy",
          element: (
            <Privacy />
          ),
        },
        {
          path: "/Registration",
          element: (
            <Registration />
          ),
        },
        {
          path: "/Contact",
          element: (
            <Contact />
          ),
        },]
    }
  ])
  return (
    <>

      {/* <Navbar /> */}
      <RouterProvider router={router} />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  )
}

export default App
