import React, { useState } from 'react'
import './Testimonials.css'
import Slider from 'react-slick'
import bottomline from '../../images/testimonialbottom.png'
import bgpattern from '../../images/testimonialbg.svg'
import rightbg from '../../images/testimonialpattern.png'
import celebex from '../../images/celebex.svg'
import mars from '../../images/mars.png'
import prime from '../../images/prime.png'
import logoa from '../../images/s1.png'
import logob from '../../images/s2.png'
import logoc from '../../images/s3.png'
import logod from '../../images/s4.png'
import logoe from '../../images/s5.png'
import reviewstars from '../../images/review.png'
import { FaArrowRight, FaArrowLeft } from 'react-icons/fa'
const logo = [celebex, mars, prime]
const name = ['Celebex', 'Mars', 'Prime']
const review = ["In an age where AI companies are a plenty, IDRAK truly stands out. Their products are not just innovative, but they come with a depth of understanding of the real-world challenges businesses face. Callwise has been a game-changer for our customer support. <b>Truly revolutionary!</b>", "Our partnership with IDRAK has redefined what we thought was possible with AI. From conversational AI to their advancements in robotics, it's clear that IDRAK isn't just on the cutting edge—they're creating it.", "As a startup founder, I've had the pleasure of integrating several IDRAK solutions into our operations. Their commitment to excellence and seamless adaptability to our needs has been exemplary. It's not just AI; it's a brighter future with IDRAK."]
const Testimonials = () => {
    const [imageIndex, setimageIndex] = useState(0)
    const NextArrow = ({ onClick }) => {
        return (
            <div className='arrow-next' onClick={onClick}>
                <FaArrowRight />
            </div>
        )
    }
    const PrevArrow = ({ onClick }) => {
        return (
            <div className='arrow-prev' onClick={onClick}>
                <FaArrowLeft />
            </div>
        )
    }
    const settings = {
        infinite: true,
        lazyload: true,
        speed: 300,
        slidesToShow: 1,
        // centerMode: true,
        centerPadding: 0,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        beforeChange: (current, next) => {
            setimageIndex(next)
        }
    }
    return (
        <>
            <div className="testimonial-main">
                <img src={bgpattern} className='testpatternbg' alt="" />
                <div className="testimonial-left">
                    <div className="testimonial-gradient"></div>
                    <div className="leftcontent">
                        <h1>Revolutionary<br />Outcomes </h1>
                        <img src={bottomline} className='bottomline' alt="" />
                        <h2>Discover what our valued<br />customers have to say!</h2>
                    </div>
                </div>
                <div className='testimonial-right'>
                    <img src={rightbg} className='rightbg' alt="" />
                    <div className='testimonials-App'>
                        <Slider {...settings}>
                            {logo.map((img, idx) => (
                                <div className={idx === imageIndex ? 'slides activeSlide' : 'slides'}>
                                    <div className='right-content'>
                                        <div className="content-heading">
                                            <div> <img src={img} alt="" /></div>
                                            <div><h1>{name[idx]}</h1></div>
                                            <div className='reviewstar'><img src={reviewstars} alt="" /></div>
                                        </div>
                                        <div className='review'>
                                            <div dangerouslySetInnerHTML={{ __html: review[idx] }} className='review-p' />
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </div>
            <div className='AR-logo'>
                <div className="AR-logo-subI">
                    <img src={logoa} alt='' />
                    <img src={logob} alt='' />
                    <img src={logoc} alt='' />
                    <img src={logod} alt='' />
                    <img src={logoe} alt='' />
                    <img src={logoa} alt='' />
                    <img src={logob} alt='' />
                    <img src={logoc} alt='' />
                    <img src={logod} alt='' />
                    <img src={logoe} alt='' />
                    <img src={logoa} alt='' />
                    <img src={logob} alt='' />
                    <img src={logoc} alt='' />
                    <img src={logod} alt='' />
                    <img src={logoe} alt='' />
                    <img src={logoa} alt='' />
                    <img src={logob} alt='' />
                    <img src={logoc} alt='' />
                    <img src={logod} alt='' />
                    <img src={logoe} alt='' />
                    <img src={logoa} alt='' />
                    <img src={logob} alt='' />
                    <img src={logoc} alt='' />
                    <img src={logod} alt='' />
                    <img src={logoe} alt='' />
                    <img src={logoa} alt='' />
                    <img src={logob} alt='' />
                    <img src={logoc} alt='' />
                    <img src={logod} alt='' />
                    <img src={logoe} alt='' />
                </div>
                <div className="AR-logo-subII">
                    <img src={logoa} alt='' />
                    <img src={logob} alt='' />
                    <img src={logoc} alt='' />
                    <img src={logod} alt='' />
                    <img src={logoe} alt='' />
                    <img src={logoa} alt='' />
                    <img src={logob} alt='' />
                    <img src={logoc} alt='' />
                    <img src={logod} alt='' />
                    <img src={logoe} alt='' />
                    <img src={logoa} alt='' />
                    <img src={logob} alt='' />
                    <img src={logoc} alt='' />
                    <img src={logod} alt='' />
                    <img src={logoe} alt='' />
                    <img src={logoa} alt='' />
                    <img src={logob} alt='' />
                    <img src={logoc} alt='' />
                    <img src={logod} alt='' />
                    <img src={logoe} alt='' />
                    <img src={logoa} alt='' />
                    <img src={logob} alt='' />
                    <img src={logoc} alt='' />
                    <img src={logod} alt='' />
                    <img src={logoe} alt='' />
                    <img src={logoa} alt='' />
                    <img src={logob} alt='' />
                    <img src={logoc} alt='' />
                    <img src={logod} alt='' />
                    <img src={logoe} alt='' />
                    <img src={logoa} alt='' />
                    <img src={logob} alt='' />
                    <img src={logoc} alt='' />
                    <img src={logod} alt='' />
                    <img src={logoe} alt='' />
                    <img src={logoa} alt='' />
                    <img src={logob} alt='' />
                    <img src={logoc} alt='' />
                    <img src={logod} alt='' />
                    <img src={logoe} alt='' />
                    <img src={logoa} alt='' />
                    <img src={logob} alt='' />
                    <img src={logoc} alt='' />
                    <img src={logod} alt='' />
                    <img src={logoe} alt='' />
                    <img src={logoa} alt='' />
                    <img src={logob} alt='' />
                    <img src={logoc} alt='' />
                    <img src={logod} alt='' />
                    <img src={logoe} alt='' />
                    <img src={logoa} alt='' />
                    <img src={logob} alt='' />
                    <img src={logoc} alt='' />
                    <img src={logod} alt='' />
                    <img src={logoe} alt='' />
                    <img src={logoa} alt='' />
                    <img src={logob} alt='' />
                    <img src={logoc} alt='' />
                    <img src={logod} alt='' />
                    <img src={logoe} alt='' />

                </div>
            </div>

            <div className='bottom-bar'>
                <a href='/Privacy' target="_blank">Privacy Policy</a>
                <a href='/Terms' target="_blank">Terms of Use</a>
            </div>
        </>
    )
}

export default Testimonials