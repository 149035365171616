import React from 'react'

const BulletSection = () => {

    return (
        <>
            <div className='bulletsection-main'>
                <div className='bulletsection-left'>
                    <div className='bulletsection-heading'>
                        <h2>
                            Who Should <span>Attend:</span>
                        </h2>
                    </div>
                    <div>
                        <p><span>•</span> Understand the fundamentals and advanced concepts of computer vision</p>
                        <p><span>•</span> Evaluate the practical applications and limitations of current technologies</p>
                        <p><span>•</span> Learn how to leverage different algorithms for optimal results</p>
                        <p><span>•</span> Innovate by combining various approaches for practical solutions</p>
                    </div>
                </div>

                <div className='bulletsection-right'>
                    <div className='bulletsection-heading'>
                        <h2>
                            Why Choose This <span>Workshop?</span>
                        </h2>
                    </div>
                    <div>
                        <div>
                            <h3>
                                Expert-Led Sessions
                            </h3>
                            <p>Gain insights from top experts for a current perspective.</p>
                        </div>
                        <div>
                            <h3>
                                Interactive Learning
                            </h3>
                            <p>Hands-on sessions offer practical skills for immediate application</p>
                        </div>
                        <div>
                            <h3>
                                Network with Peers
                            </h3>
                            <p>Connect with passionate professionals in computer vision.</p>
                        </div>
                    </div>
                </div>
            </div>
           
        </>
    )
}

export default BulletSection
