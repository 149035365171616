import React from 'react'
import { useState } from 'react'
import './Callwise.css'
import bullet from '../../images/Bullet.svg'
import playbtn from '../../images/playbtn.png'
import stopbtn from '../../images/stopbtn.png'
import callwisebg from '../../images/callwisebgg.png'
import callwisebgright from '../../images/callwisebgright.svg'
import finalexpense from '../../images/Audio/Final Expense.mp3'
import orderconfirmation from '../../images/Audio/Order Confirmation.mp3'
import returnpolicy from '../../images/Audio/Return Policy.mp3'
import redirect from '../../images/redirect.png'


const Callwise = () => {
    const inbound = [
        {
            text: "Final Expense",
            imgplay: playbtn,
            imgstop: stopbtn,
            audio: finalexpense

        },
        {
            text: "Order Confirmation",
            imgplay: playbtn,
            imgstop: stopbtn,
            audio: orderconfirmation

        },
        {
            text: "Return Policy",
            imgplay: playbtn,
            imgstop: stopbtn,
            audio: returnpolicy
        },
    ];
    const [isPlaying, setIsPlaying] = useState(false);
    const [activeLineIndex, setActiveLineIndex] = useState(-1);
    const [activeIndex, setActiveIndex] = useState(null);
    const [activeAudioElement, setActiveAudioElement] = useState(null);

    const playButtonLines = Array.from({ length: 20 }, (_, i) => `line${i + 1}`);

    const startAnimation = (index) => {
        setActiveIndex(index); console.log(index, activeIndex)
        setIsPlaying(true);
        setActiveLineIndex(-1);


        if (activeAudioElement) {
            activeAudioElement.pause();
            activeAudioElement.currentTime = 0;

            if (index === activeIndex) {
                setActiveAudioElement(null);
                setActiveIndex(null);
                setIsPlaying(false)
                return;
            }
        }
        const intervalId = setInterval(() => {
            setActiveLineIndex((index) => {
                if (index >= playButtonLines.length - 1) {
                    clearInterval(intervalId);
                    setIsPlaying(false);
                }
                return index + 1;
            });
        }, 1000);
        // Play the new audio
        const audioElement = new Audio(inbound[index].audio);
        audioElement.setAttribute("id", "inbound-audio");
        audioElement.play();
        setActiveAudioElement(audioElement);
    };


    return (
        <>
            <div className="bg-callwise" >
                <img src={callwisebg} className='callwise-bg' alt="" />
                <div className="callwise-wrapper" id='Callwise'>
                    <div className="callwise-left">
                        <h1>Callwise</h1>
                        <p>Dive into the next-gen realm of communication with Callwise, where every communication  feels like a tailor-made conversation. Callwise redefines customer interactions, blending efficiency and personalization seamlessly. It's not just about calls; it's about crafting connections. </p>
                        <div className='context-bullets'>
                            <div className="bullets">
                                <img src={bullet} alt="" />
                                <p>No more miscommunication.</p>
                            </div>
                            <div className="bullets">
                                <img src={bullet} alt="" />
                                <p>Faster, precise answers.</p>
                            </div>
                            <div className="bullets">
                                <img src={bullet} alt="" />
                                <p>Targeted and impactful.</p>
                            </div>
                            <div className="bullets">
                                <img src={bullet} alt="" />
                                <p>Boost loyalty and satisfaction.</p>
                            </div>
                        </div>
                        {/* <div className="gradient"></div> */}
                        <a href="https://callwise.ai/"><button className='callwise-button'>Callwise <img src={redirect} alt="" /></button></a>
                    </div>
                    <div className="callwise-right">
                        <img src={callwisebgright} className='callwisebgright-bg' alt="" />
                        <div className="playroom-flex-left">
                            {inbound.map((data, index) => (
                                <div className="play-btn-wrapper" key={index}>
                                    <p>{data.text}</p>
                                    <div
                                        className={
                                            index === activeIndex
                                                ? "flex-play-btn-l-d active"
                                                : "flex-play-btn-l-d"
                                        }
                                    >
                                        <div
                                            className="ply-btn-bg"
                                            onClick={() => startAnimation(index)}
                                        >
                                            <img
                                                src={index !== activeIndex ? data.imgplay : data.imgstop}
                                                style={{ width: '11px' }} alt="" />
                                        </div>
                                        <div className="play-btn-lines-main">
                                            {playButtonLines.map((line, i) => (
                                                <div
                                                    key={line}
                                                    className={`play-btn-lines ${line} ${activeIndex === index && i <= activeLineIndex
                                                        ? "active"
                                                        : "inactive"
                                                        }`}
                                                    style={{ transitionDelay: `${(i + 1) * 10}ms` }}
                                                ></div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Callwise
