import React from 'react'
import './SectionII.css'
import sub1 from '../../images/sub1.svg'
import sub2 from '../../images/sub2.svg'
import sub3 from '../../images/sub3.svg'
const SubSection = ({ h1, paragraph, image }) => {
  return (
    <>
      <div className='sub-section'>
        <img src={image} className='subsection-image' alt=""/>
        <h1>{h1}</h1>
        <p>{paragraph}</p>
      </div>
    </>
  );
};
const SectionII = () => {
  return (
    <div className='sectionII d-flex'>
      <SubSection
        h1="WHERE VISION TAKES FLIGHT"
        paragraph="IDRAK stands at the AI vanguard, morphing visionary concepts into transformative solutions."
        image={sub1}
      />
      <SubSection
        h1="AN EMPOWERMENT HUB"
        paragraph="We gift  individuals the keys to soar, evolve, and turn their business dreams into realities."
        image={sub2}
      />
      <SubSection
        h1="IT'S NOT JUST ABOUT INNOVATION"
        paragraph="We're passionate about a future that's kind to our planet and packed with possibilities."
        image={sub3}
      />
    </div>
  )
}
export default SectionII
