import React from 'react'

const LearnSec = () => {
    return (
        <>
            <div className='learn-sec-main'>
                <div className='learn-sec-main-heading'>
                    <h2>What You Will <span>Learn:</span></h2>
                </div>
                <div className='learn-sec-main-points'>
                    <div className='learn-sec-main-points-upper'>
                        <div className='learn-sec-point'>
                            <h3>
                                <span>•</span> A Solid Foundation
                            </h3>
                            <p>
                                Progress from basic to advanced techniques for visual data interpretation.
                            </p>
                        </div>
                        <div className='learn-sec-point'>
                            <h3>
                                <span>•</span> Cutting-Edge Techniques
                            </h3>
                            <p>
                                Machine learning and deep learning
                                for precise real-world object
                                recognition.
                            </p>
                        </div>
                        <div className='learn-sec-point'>
                            <h3>
                                <span>•</span> Real-World Applications
                            </h3>
                            <p>
                                Object detection in diverse domains,
                                posing challenges and offering vast
                                possibilities.
                            </p>
                        </div>
                    </div>
                    <div className='learn-sec-main-points-lower'>
                        <div className='learn-sec-point'>
                            <h3>
                                <span>•</span> Choosing the Right Tools
                            </h3>
                            <p>
                                Assessing algorithm strengths and
                                weaknesses to match project
                                requirements.
                            </p>
                        </div>
                        <div className='learn-sec-point'>
                            <h3>
                                <span>•</span> Hands-On Experience
                            </h3>
                            <p>
                                Blend novel and traditional methods
                                for practical, innovative solutions.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LearnSec
